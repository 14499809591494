import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { jobs_helper } from "@/helpers/jobs_helper";
import { speciality_helper } from "@/helpers/speciality_helper";
import { testimonials_helper } from "@/helpers/testimonials_helper";
import sn from "@/snippets";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  const specialities = speciality_helper.fetch();

  return {
    props: {
      meta: { title: createTitle("Home") },
      content: [
        {
          component: "Hero",
          props: {
            title: {
              path: "page.home.component.Hero.title",
              placeholder: "A leading provider of healthcare recruitment solutions",
            },
            videos: [
              { video_embed_url: "/homepage-videos/doctors-talking.mp4" },
              { video_embed_url: "/homepage-videos/surgery.mp4" },
              { video_embed_url: "/homepage-videos/mental-health-video.mp4" },
              { video_embed_url: "/homepage-videos/two-females-video.mp4" },
            ],
          },
        },
        // { component: "LeadooWidget" },
        // { component: "Divider" },
        sn.gridButtons({
          className: "bg-white py-4 py-md-5 text-left",
          title: { path: "page.home.component.GridButtonsGrid.title", placeholder: "Make your next career move" },
          description: {
            path: "page.home.component.GridButtonsGrid.description",
            placeholder:
              "Global Medics provides career solutions for healthcare professionals, operating in Australia, New Zealand, Ireland and the United Kingdom. We have permanent, contract and locum medical jobs for all grades and specialties in metropolitan, rural and regional hospitals and clinics across the globe.",
          },
          items: specialities.map((i) => ({
            title: i["title"] ?? null,
            img: i["image"] ?? null,
            href: i["link"] ?? "#",
          })),
        }),
        { component: "Divider" },
        {
          component: "LatestJobs",
          props: {
            title: { path: "page.home.component.LatestJobs.title", placeholder: "Latest Jobs" },
            items: jobs_helper.fetch(),
          },
        },
        {
          component: "BlockQuote",
          props: {
            className: "bg-primary text-white",
            items: testimonials_helper.fetch(),
            carousel: true,
            visibleCount: 1,
          },
        },
      ],
    },
  };
}
